'use client'

import SVGnewWindow from '@fractal/primitives/SVGs/icons/newWindow'
import SVGshare from '@fractal/primitives/SVGs/socials/share'
import { BlockContent } from 'app/components/BlockContent/BlockContent.client'
import ShareModal from 'app/components/Share/Share.client'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import useTranslation from 'app/i18n/client'
import React, { useState } from 'react'

interface IDownloadableAssetButtonsProps {
  url: string
  title: string
  sys: {
    id: string
  }
}

function DownloadableAssetButtons({
  title,
  url,
  sys,
}: IDownloadableAssetButtonsProps) {
  const [open, toggleOpen] = useState(false)
  const { t } = useTranslation()

  const onSharedClicked = () => {
    toggleOpen(true)
  }

  return (
    <div className='text-left mt-sm-4 px-sm-1 pt-sm-5 border-sm-top border-static-grey4'>
      <div className='d-sm-flex flex-sm-justify-between flex-sm-align-center'>
        <TrackClick
          as={BlockContent}
          href={url}
          target='_blank'
          isDownload
          className='Btn Btn--primary Btn--responsive no-underline'
          ariaLabel={`${t('download')} ${title} pdf`}
          dataTestId='articles-downloadableasset-link'
          onClickDelayed={() => {
            const queryParams = new URLSearchParams(window?.location?.search)
            const fromParam = queryParams.get('from')
            window.open(url, `${fromParam === 'app' ? '_self' : '_blank'}`)
          }}
          onClick={(e) => e.preventDefault()}
          isEngagementEvent
          trackProps={{
            eventType: 'download',
            unitName: 'download_share',
            unitLocation: 'inline',
            linkedContentId: sys.id,
            linkedContentUrl: url,
            componentId: null,
          }}
        >
          {t('download')}
          <SVGnewWindow
            width='20'
            height='20'
            className='ml-sm-2 fill-svg w-sm-3 w-md-4'
          />
          <span className='sr-only'>{`$${t('opens-in-new-tab')}`}</span>
        </TrackClick>

        <TrackClick
          as='button'
          type='button'
          data-testid='articles-downloadableasset-share'
          className='Btn-link'
          onClick={onSharedClicked}
          isEngagementEvent
          trackProps={{
            eventType: 'share_start',
            unitName: 'download_share',
            unitLocation: 'inline',
            linkedContentId: sys.id,
            linkedContentUrl: url,
            componentId: null,
          }}
        >
          <SVGshare width='20' height='20' className='ml-sm-2 fill-svg' />
          <span className='sr-only'>
            {`${t('share')}, ${t('opens-in-new-tab')}`},
          </span>
        </TrackClick>
        <ShareModal
          open={open}
          onRequestClose={() => toggleOpen(false)}
          href={url}
          title={title}
          hideShareButton
        />
      </div>
    </div>
  )
}

export default DownloadableAssetButtons
