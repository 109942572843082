'use client'

import SVGclose from '@fractal/primitives/SVGs/icons/close'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import React, { useState } from 'react'
import ReactModal from 'react-modal'
import lowerCaseAndUnderscore from 'utils/lowerCaseAndUnderscore'

const translations = {
  buttonAriaLabel: 'activate for definition',
}

const customStyles = {
  content: {
    right: null,
    bottom: null,
    border: null,
    background: null,
    borderRadius: null,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 480,
    width: '100%',
    padding: 20,
  },
}

interface Props {
  term: React.ReactNode
  children: React.ReactNode
}

function GlossaryFieldClient({ term, children }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const text = term?.toString() || ''

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <TrackImpression
        as='fragment'
        buttonName={lowerCaseAndUnderscore(text)}
        unitName='glossary'
        unitLocation='inline'
        componentId={null}
      >
        <TrackClick
          as='button'
          type='button'
          className='Btn-link text-dotted'
          data-testid='article-glossary-term'
          aria-label={`${text}: ${translations.buttonAriaLabel}`}
          onClick={toggle}
          isEngagementEvent={false}
          trackProps={{
            buttonName: lowerCaseAndUnderscore(text),
            unitName: 'glossary',
            unitLocation: 'inline',
            clickType: 'button',
            componentId: null,
          }}
        >
          {term}
        </TrackClick>
      </TrackImpression>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        preventScroll
      >
        <div
          data-testid='article-glossary-modal'
          className='p-sm-5 bg-background-high-white shadow rounded-sm-3 p-sm-relative o-sm-y-scroll'
        >
          <button
            type='button'
            className='Btn-link p-sm-absolute p-sm-4 top-sm-0 right-sm-0'
            onClick={() => setIsOpen(false)}
            data-testid='glossary-modal-close-button'
            aria-label='close modal'
          >
            <SVGclose className='vicon' />
          </button>
          <p className='d-sm-block f-sm-4 mb-sm-2 lh-condensed text-bold'>
            {term}
          </p>
          <div role='definition'>{children}</div>
        </div>
      </ReactModal>
    </>
  )
}

export default GlossaryFieldClient
