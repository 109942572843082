'use client'

import React, { useEffect, useRef, useState } from 'react'

interface IEmbeddedHtmlProps {
  text: string
}

const HEIGHT_FIXING = 20

function EmbeddedHtml({ text }: IEmbeddedHtmlProps) {
  const ref = useRef<any>()
  const [height, setHeight] = useState('600px')
  const [isLoaded, setIsLoaded] = useState(false)
  const hasResizeObserver =
    typeof window === 'object' && typeof window.ResizeObserver !== 'undefined'

  useEffect(() => {
    if (
      !ref.current ||
      !ref.current.contentWindow ||
      !isLoaded ||
      !hasResizeObserver
    )
      return

    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(`${entries[0].contentRect.height + HEIGHT_FIXING}px`)
    })
    resizeObserver.observe(ref.current.contentWindow.document.body)

    return () => resizeObserver.disconnect()
  }, [ref.current, isLoaded])

  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      ref={ref}
      style={{ height, width: '100%', border: 'none' }}
      srcDoc={text}
      scrolling={hasResizeObserver ? 'no' : 'auto'}
      onLoad={() => {
        setIsLoaded(true)
      }}
    />
  )
}

export default EmbeddedHtml
