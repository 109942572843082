'use client'

import SVGplay from '@fractal/primitives/SVGs/icons/play'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import TrackImpression from 'app/components/TrackImpression/TrackImpression.client'
import getVideoId from 'get-video-id'
import dynamic from 'next/dynamic'
import React, { useRef } from 'react'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

interface IVideoPlayer {
  videoId?: string
  url: string
  applyAspectRatio?: boolean
  className?: string
  fullSizeContainer?: boolean
  containerClassName?: string
}

// The fixing is to ensure the event of 100% of video playing is going to be fired
// as the last event the component fires is around 0.9987
const fixingPlayed = 0.01
const interval = 10
const unitName = 'video_player'
const buttonName = 'video_player'
const unitLocation = 'inline'
const defaultPlayedRef = {
  played: 0,
  playedSeconds: 0,
}

function VideoPlayer({
  url,
  videoId,
  fullSizeContainer,
  applyAspectRatio = true,
  containerClassName = 'grid container',
  className = 'grid-col-sm-12 grid-start-lg-3 grid-col-lg-8',
}: IVideoPlayer) {
  const playedRef = useRef(defaultPlayedRef)
  const videoInfo = getVideoId(url)
  if (url.includes('facebook')) {
    // get-video-id doesn't support facebook yet
    videoInfo.service = 'facebook' as any
  }

  const getVideoStyle = (): {
    videoPlayer: React.CSSProperties
    container: React.CSSProperties
  } => {
    const params = new URLSearchParams(url)
    let width = params.get('w') || params.get('width')
    let height = params.get('h') || params.get('height')

    if ((!width || !height) && applyAspectRatio) {
      width = '16'
      height = '9'
    }

    if (width && height) {
      return {
        container: {
          position: 'relative',
          paddingTop: `calc(${height} / ${width} * 100%)`,
          margin: 'auto',
        },
        videoPlayer: {
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }
    }

    return {
      container: {
        width: '100%',
        height: 'auto',
      },
      videoPlayer: {
        width: width ? `${width}px` : '100%',
        height: height ? `${height}px` : 'auto',
        margin: 'auto',
      },
    }
  }

  const handleOnPlay = () => {
    trackEngagement({
      eventType: 'video_play',
      platform: videoInfo.service as any,
      unitLocation,
      unitName,
      componentId: null,
    })
  }

  const handleOnProgress = (event) => {
    const playedSecondsByInterval = Math.floor(event.playedSeconds / interval)
    if (playedSecondsByInterval > playedRef.current.playedSeconds) {
      playedRef.current.playedSeconds = playedSecondsByInterval
      trackEngagement({
        eventType: 'video_duration',
        platform: videoInfo.service as any,
        unitLocation,
        unitName,
        componentId: null,
      })
    }

    const playedByInterval = Math.floor(
      ((event.played + fixingPlayed) * 100) / interval
    )
    if (playedByInterval > playedRef.current.played) {
      playedRef.current.played = playedByInterval
      trackEngagement({
        eventType: 'video_percentage_played',
        platform: videoInfo.service as any,
        unitLocation,
        unitName,
        componentId: null,
      })
    }
  }

  const styles = getVideoStyle()

  return (
    <div
      className={`no-print ${
        fullSizeContainer ? '' : containerClassName
      } my-sm-4`}
      data-testid='article-videoplayer'
    >
      <div className={className}>
        <TrackImpression
          as='fragment'
          unitName={unitName}
          unitLocation={unitLocation}
          isExternal={false}
          isContent={false}
          buttonName={buttonName}
          linkedContentId={videoId}
          componentId={null}
        />
        <div style={styles.container} className='text-center'>
          <ReactPlayer
            controls
            url={url}
            style={styles.videoPlayer}
            width={styles.videoPlayer.width}
            height={styles.videoPlayer.height}
            onStart={handleOnPlay}
            onProgress={handleOnProgress}
            light={
              videoInfo.service === 'youtube'
                ? `https://i.ytimg.com/vi/${videoInfo.id}/hqdefault.jpg`
                : true
            }
            playing
            playIcon={
              <SVGplay
                width={50}
                height={50}
                className='vicon fill-static-white'
              />
            }
          />
        </div>
      </div>
    </div>
  )
}

export default VideoPlayer
