'use client'

import SVGcaretRight from '@fractal/primitives/SVGs/icons/caretRight'
import TrackClick from 'app/components/TrackClick/TrackClick.client'
import { useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import styles from './Accordion.module.scss'

const unitName = 'accordion'

interface IAccordionItemProps {
  title: string
  position: number
  children: React.ReactNode
}

function AccordionItem({ title, children, position }: IAccordionItemProps) {
  const [isOpen, setVisible] = useState(false)
  const uniqueId = uuidV4()

  const toggle = () => {
    setVisible((curr) => !curr)
  }

  const handleKeyboardNavigation = (event) => {
    if (event.keyCode === 32 || event.keyCode === 13) {
      event.preventDefault()
      toggle()
    }
  }

  return (
    <div data-testid='accordion-item'>
      <TrackClick
        as='h3'
        className={`py-sm-5 f-sm-4 f-md-5 lh-condensed d-sm-flex flex-sm-align-center flex-sm-justify-between ${styles.pointer}`}
        role='button'
        onKeyDown={(e) => handleKeyboardNavigation(e)}
        data-testid='accordion-item-title'
        onClick={toggle}
        aria-expanded={isOpen}
        aria-controls={uniqueId}
        tabIndex={0}
        data-position={position}
        isEngagementEvent={false}
        trackProps={{
          unitName,
          buttonName: 'expand',
          unitLocation: 'inline',
          isContent: true,
          positionInUnit: position,
          componentId: null,
          clickType: 'button',
        }}
      >
        {title}
        <SVGcaretRight
          className={`${isOpen ? 'rotate-270' : 'rotate-90'} 
          w-sm-4 h-sm-4 d-inline-block float-sm-right fill-static-scarlet flex-sm-shrink-0 ml-sm-3`}
        />
      </TrackClick>
      <div
        className={`pb-sm-5 ${styles.accordionItem} d-block-print`}
        id={uniqueId}
        data-testid='accordion-item-content'
        aria-hidden={!isOpen}
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        {children}
      </div>
    </div>
  )
}

export default AccordionItem
