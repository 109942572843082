interface IIFrameEmbedProps {
  url: string
  width?: string
  height?: string
  applyAspectRatio?: boolean
  rounded?: boolean
  border?: boolean
}

function IFrameEmbed({
  url,
  width: defaultWidth,
  height: defaultheight,
  applyAspectRatio = true,
  rounded = true,
  border = false,
}: IIFrameEmbedProps) {
  const getIframeStyle = (): {
    iframe: React.CSSProperties
    container: React.CSSProperties
  } => {
    const params = new URLSearchParams(url)
    let width = params.get('w') || params.get('width') || defaultWidth
    let height = params.get('h') || params.get('height') || defaultheight

    if ((!width || !height) && applyAspectRatio) {
      width = '16'
      height = '9'
    }

    if (width && height) {
      return {
        container: {
          position: 'relative',
          paddingTop: `calc(${height} / ${width} * 100%)`,
          margin: 'auto',
        },
        iframe: {
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }
    }

    return {
      container: {
        width: '100%',
        height: 'auto',
      },
      iframe: {
        width: width ? `${width}px` : '100%',
        height: height ? `${height}px` : 'auto',
        margin: 'auto',
      },
    }
  }

  const styles = getIframeStyle()

  return (
    <div className='d-block-print' data-testid='article-iframeembed'>
      <div style={styles.container} className='text-center'>
        <iframe
          src={url}
          className={`${rounded ? 'rounded-sm-1' : ''} 
            ${
              border ? 'border-sm-1 border-static-grey3' : 'border-sm-0'
            } o-sm-hidden`}
          style={styles.iframe}
          width={styles.iframe.width}
          height={styles.iframe.height}
          title='External content embed'
        />
      </div>
    </div>
  )
}

export default IFrameEmbed
