'use client'

import ReactPlayer from 'react-player'
import VideoPlayer from '../../../VideoPlayer/VideoPlayer.client'
import IFrameEmbed from '../IFrameEmbed'

interface IVideoEmbedClientProps {
  sys: {
    id: string
  }
  videoUrl: string
}

function VideoEmbedClient({ videoUrl, sys }: IVideoEmbedClientProps) {
  if (ReactPlayer.canPlay(videoUrl)) {
    return (
      <VideoPlayer
        url={videoUrl}
        videoId={sys.id}
        applyAspectRatio={!videoUrl?.includes('facebook')}
        className='rounded-sm-1 o-sm-hidden'
        containerClassName=''
      />
    )
  }

  const isPodcast = videoUrl?.includes('libsyn')
  return (
    <IFrameEmbed
      url={videoUrl}
      applyAspectRatio={!isPodcast}
      height={isPodcast ? '90' : undefined}
      rounded={!isPodcast}
      border={isPodcast}
    />
  )
}

export default VideoEmbedClient
